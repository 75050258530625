<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('billing.manualPricing') | title" />

    <huboo-table
      id="manualPricingTable"
      @row-clicked="onRowSelected"
      @filter="handleFilter"
      @update:options="handleUpdateOptions"
      :filters="filters"
      :headers="headers"
      :items="items"
      v-bind="options"
      clickable
      localPagination
      :server-items-length="serverItemsLength"
      :loading="loading"
      hide-search
      @remove="openConfirmationModal"
      @confirm-delete="onRemove"
      :remove="isAdmin"
    >
      <template #actions>
        <export-button
          v-if="exportUrl"
          :file-name="exportName"
          :export-url="exportUrl"
          :client="box.hubooBox.toString()"
        />

        <v-btn
          :disabled="loading"
          color="primary"
          @click="onCreateSelected"
          id="manualPricingCreateButton"
        >
          {{ $t('billing.createManualPricing') }}
        </v-btn>
      </template>
    </huboo-table>

    <component
      :is="modal.component"
      v-model="modal.show"
      :selected="selected"
      @selected:updated="onSelectedUpdated"
    ></component>
  </huboo-page>
</template>

<script>
import ManualPricingCreateModal from '@/views/pages/billing/manualPricing/ManualPricingCreateModal'
import ManualPricingEditModal from '@/views/pages/billing/manualPricing/ManualPricingEditModal'
import { formatDate, orderBy } from '@/views/pages/billing/billingViewHelpers'
import { title } from '@/utilities/filters'
import { goTo } from '@/utilities/helpers/router'
import Vue from 'vue'
import ClientMixin from '@/mixins/client'
import ExportButton from '@/components/pages/ExportButton.vue'

export default {
  name: 'ManualPricing',
  mixins: [ClientMixin],
  components: {
    'manual-pricing-create-modal': ManualPricingCreateModal,
    'manual-pricing-edit-modal': ManualPricingEditModal,
    'export-button': ExportButton,
  },
  data: () => ({
    modal: {
      component: '',
      show: false,
    },
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    selected: null,
  }),
  computed: {
    box() {
      return this.$store.getters['billing/getSelectedBox']
    },
    filters() {
      // TODO: Activate when filtering available via the API
      return null
    },
    exportUrl() {
      return this.manualPriceLists?.exportUrl
    },
    exportName() {
      return this.$options.name
    },

    headers() {
      return [
        {
          text: title(this.$t('common.name')),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: title(this.$t('common.price')), sortable: false, value: 'price' },
        { text: title(this.$t('billing.originRegion')), sortable: true, value: 'originRegion' },
        { text: title(this.$t('common.updated')), sortable: false, value: 'updatedAt' },
      ]
    },
    manualPriceLists() {
      return this.$store.getters['billing/getManualPriceLists']
    },
    items() {
      return (
        this.manualPriceLists?.data?.map(m => {
          const v = { ...m }
          v.updatedAt = this.formatDate(v.updatedAt)
          return v
        }) || []
      )
    },
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: this.url,
      })
    },
    meta() {
      return this.manualPriceLists?.meta || { total: this.items.length }
    },
    orderBy() {
      return orderBy(this)
    },
    serverItemsLength() {
      return this.meta.total || 0
    },
    url() {
      return '/huboo-box/' + this.box?.hubooBox + '/manual-price-list'
    },
    isAdmin() {
      return this.$store.getters['core/getIsAdmin']
    },
  },
  watch: {
    'modal.show': function(s) {
      if (s === false) this.fetch()
    },
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch('billing/fetchRegions')
  },
  methods: {
    fetch() {
      if (!this.loading) {
        const { options = {}, orderBy } = this
        const { page } = options
        const params = {}
        if (page) params.page = page
        if (orderBy) params.orderBy = orderBy
        this.$store.dispatch('billing/fetchManualPriceLists', { params, url: this.url })
      }
    },
    formatDate(d) {
      return formatDate(this, d)
    },
    goTo,
    handleFilter(e) {
      console.debug('filtering...', e)
    },
    handleUpdateOptions(e) {
      Vue.set(this, 'options', e)
    },
    onRowSelected(v) {
      this.selected = v
      this.modal = { component: 'manual-pricing-edit-modal', show: true }
    },
    onCreateSelected() {
      this.modal = { component: 'manual-pricing-create-modal', show: true }
    },
    onSelectedUpdated() {
      this.selected = this.$store.getters['billing/getSelectedManualPriceList']
    },
    onRemove() {
      this.$store
        .dispatch('billing/removeManualPriceLists', {
          id: this.selected.id,
        })
        .finally(() => {
          this.fetch()
        })
    },
    openConfirmationModal(selectedRow) {
      this.selected = selectedRow
    },
  },
}
</script>

<style lang="scss" scoped></style>
